import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import api from '../api';
import moment from 'moment';
import { toast } from 'react-toastify';
import store from "../redux/store";
import Marquee from "react-easy-marquee";

const Gainer = ({ p, schange }) => (
  <>
    <span style={{ fontWeight: "bold", color: "#fff" }}>&nbsp; {p.Symbol} &#x20A6;{p.Close} <span className="fa fa-caret-up" style={{ color: "rgb(89 219 27)", verticalAlign: "middle" }}></span> <span style={{ color: "rgb(89 219 27)" }}> +{parseFloat(schange).toFixed(2)}%</span></span>
  </>
)

const Loser = ({ p, schange }) => (
  <>
    <span style={{ fontWeight: "bold", color: "#fff" }}>&nbsp; {p.Symbol} &#x20A6;{p.Close} <span className="fa fa-caret-down" style={{ color: "#f00", verticalAlign: "middle" }}></span> <span style={{ color: "#f00" }}>{parseFloat(schange).toFixed(2)}%</span></span>
  </>
)

const Other = ({ p, schange }) => (
  <>
    <span style={{ fontWeight: "bold", color: "#fff" }}>
      &nbsp; {p.Symbol} &#x20A6;{p.Close} <span className="fa fa-caret-left" style={{ color: "#FFC200", verticalAlign: "middle" }}></span>&nbsp;
      <span className="fa fa-caret-right" style={{ color: "#FFC200", verticalAlign: "middle" }}></span>
      <span style={{ color: "#FFC200" }}>
        {parseFloat(schange).toFixed(2)}%
      </span>
    </span>
  </>
)

class DefaultNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prices: [],
      date: ''
    }
  }

  componentDidMount() {
    let pricelist = this.getCookie("pricelist");
    if (pricelist) {
      let data = JSON.parse(localStorage.getItem("pricelist") ? localStorage.getItem("pricelist") : []);
      this.setState({
        prices: data.data.reverse(),
        date: moment(data.date).format("MMM DD, YYYY")
      });
    } else {
      this.fetchDocs();
    }
  }

  // componentDidUpdate() {
  //   if (!this.state.prices.length) {
  //     let pricelist = this.getCookie("pricelist");
  //     if (pricelist) {
  //       let data = localStorage.getItem("pricelist");
  //       this.setState({
  //         prices: JSON.parse(data)
  //       });
  //     } else {
  //       this.fetchDocs();
  //     }
  //   }
  // }

  setCookie(name) {
    var currentDate = new Date();
    var expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours() + 1, 0, 0);
    var expires = "; expires=" + expirationDate.toGMTString();
    document.cookie = name + "=" + "true" + expires + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  fetchDocs() {
    api.get(`/get/latestpricelist`)
      .then(response => {
        this.setState({
          prices: response.data.data.reverse(),
          date: moment(response.data.date).format("MMM DD, YYYY")
        });
        if (response.data.data.length) {
          this.setCookie("pricelist")
          localStorage.setItem("pricelist", JSON.stringify(response.data));
        }
      }).catch(error => {
        let err = store.getState().error;
        if (err) {
          toast.error(err.message);
          this.props.history.push(err.redirectUrl)
        }
        console.log(error)
      });
  }

  priceList() {
    return this.state.prices.map((p, i) => {
      let schange = p.Close - p.PClose;
      if (schange < 0) {
        return <Loser key={i} p={p} schange={schange} />
      } else if (schange > 0) {
        return <Gainer key={i} p={p} schange={schange} />
      } else {
        return <Other key={i} p={p} schange={schange} />
      }
    })
  }

  render() {
    return (
      <>
        <header className="header header-sticky default">
          <div className="topbar">
            <div className="container">
              <div className="topbar-inner">
                <div className="row">
                  <div className="col-12">
                    <div className="d-block d-md-flex align-me-center">
                      <div className="me-3 d-inline-block">
                        <a href="mailto:info@dynamicportfolio.com"><i className="fas fa-envelope me-2 fa-flip-horizontal"></i>
                          info@dynamicportfolio.com</a>,&nbsp;<a href="mailto:invest@dynamicportfolio.com">
                          invest@dynamicportfolio.com</a>
                      </div>
                      <div className="me-auto d-inline-block">
                        <a href="tel:2348055550606"><i className="fa fa-phone me-2 fa fa-flip-horizontal"></i>+(234) 0805 555 0606</a>
                      </div>
                      <div className="social d-inline-block">
                        <ul className="list-unstyled">
                          <li><a href={'https://www.facebook.com/DynamicPortfolioLimited'} target={'_blank'}> <i className="fab fa-facebook-f"></i> </a></li>
                          <li><a href={'https://www.twitter.com/DynamicpLimited'} target={'_blank'}> <i className="fab fa-twitter"></i> </a></li>
                          <li><a href={'https://www.instagram.com/DynamicPortfolioLtd'} target={'_blank'}> <i className="fab fa-instagram"></i> </a></li>
                          <li><a href={'https://www.linkedin.com/company/dynamic-portfolio-limited'} target={'_blank'}> <i className="fab fa-linkedin-in"></i> </a></li>
                          <li><a href={'https://api.whatsapp.com/send/?phone=%2B2348055550606&text=Hello&app_absent=0'} target={'_blank'}> <i className="fab fa-whatsapp"></i> </a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className="navbar navbar-static-top navbar-expand-xl">
            <div className="container position-relative">
              <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target=".navbar-collapse"><i className="fas fa-align-left"></i></button>
              <Link className="navbar-brand" to={"/"}>
                <img className="img-fluid" src={require("../../dassets/images/logo.jpg")} alt="logo" />
              </Link>
              <div className="navbar-collapse collapse justify-content-end justify-content-xl-center">
                <ul className="nav navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to={"/"}><span>Home</span></Link>
                  </li>
                  <li className="dropdown nav-item">
                    <Link className="nav-link" to={"javascript:void(0)"} data-bs-toggle="dropdown">About<i className="fas fa-chevron-down fa-xs"></i></Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to={"/about"}><span>About us</span></Link></li>
                      <li className="dropdown-submenu">
                        <Link className="dropdown-item dropdown-toggle" to={"#!"}><span>Teams</span><i className="fas fa-chevron-right fa-xs"></i></Link>
                        <ul className="dropdown-menu right-side">
                          <li><Link className="dropdown-item" to={"/board-of-directors"}><span>Board of Directors</span></Link></li>
                          <li><Link className="dropdown-item" to={"/management-team"}><span> Key Management Team</span></Link></li>
                        </ul>
                      </li>
                      {/* <li><HashLink className="dropdown-item" to={"/about#oa"}><span>Our Approach</span></HashLink></li>
                    <li><HashLink className="dropdown-item" to={"/about#wp"}><span>Why we are preferred</span></HashLink></li> */}
                      <li><Link className="dropdown-item" to={"/our-experience"}><span>Our Experience</span></Link></li>
                    </ul>
                  </li>
                  <li className="dropdown nav-item">
                    <Link className="nav-link" to={"javascript:void(0)"} data-bs-toggle="dropdown">Services<i className="fas fa-chevron-down fa-xs"></i></Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to={"/services/issuing-house"}><span>Issuing House</span></Link></li>
                      <li><Link className="dropdown-item" to={"/services/stockbroking"}><span>Stockbroking</span></Link></li>

                      <li><Link className="dropdown-item" to={"/services/financial-advisory"}><span>Financial Advisory</span></Link></li>
                      <li><Link className="dropdown-item" to={"/services/portfolio-management"}><span>Portfolio Management</span></Link></li>
                      <li><Link className="dropdown-item" to={"/services/fund-management"}><span>Fund Management</span></Link></li>
                      <li><Link className="dropdown-item" to={"/services/lagos-commodities-and-future-exchange"}><span>Lagos Commodities and Future Exchange</span></Link></li>
                    </ul>
                  </li>
                  <li className="dropdown nav-item">
                    <Link className="nav-link" to={"javascript:void(0)"} data-bs-toggle="dropdown">Research<i className="fas fa-chevron-down fa-xs"></i></Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to={"/pricelist"}><span>Price List</span></Link></li>
                      <li><Link className="dropdown-item" to={"/ngx-index"}><span>NGX Market Index</span></Link></li>
                      <li><Link className="dropdown-item" to={"/research/daily stock market report"}><span>Daily Market Report</span></Link></li>
                      <li><Link className="dropdown-item" to={"/research/weekly stock market report"}><span>Weekly Market Report</span></Link></li>
                      <li><a target={"_blank"} className="dropdown-item" href={"https://ngxgroup.com/exchange/data/corporate-disclosures"}><span>Company Report</span></a></li>
                      <li><a target={"_blank"} className="dropdown-item" href={"https://ngxgroup.com/exchange/data/corporate-actions"}><span>Corporate Actions</span></a></li>
                      {/* <li><Link className="dropdown-item" to={"/research/company analysis"}><span>Company Report</span></Link></li>
                      <li><Link className="dropdown-item" to={"/research/corporate actions"}><span>Corporate Actions</span></Link></li> */}
                      {/* <li><Link className="dropdown-item" to={"/research/financial news"}><span>Financial News</span></Link></li> */}
                      <li><Link className="dropdown-item" to={"/research/downloads"}><span>Downloads</span></Link></li>
                      {/* <li><Link className="dropdown-item" to={"#!"}><span>Dividends and Bonus</span></Link></li>
                    <li><Link className="dropdown-item" to={"#!"}><span>Company Information</span></Link></li> */}
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/faqs"}><span>FAQs</span></Link>
                  </li>
                  {/* <li className="nav-item">
                  <Link className="nav-link" to={"#!"} >Blog</Link>
                </li> */}
                  <li className="dropdown nav-item">
                    <Link className="nav-link" to={"javascript:void(0)"} data-bs-toggle="dropdown">Accounts<i className="fas fa-chevron-down fa-xs"></i></Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to={"/individual-forms/create"}><span>Individual Account Signup</span></Link></li>
                      <li><Link className="dropdown-item" to={"/joint-forms/create"}><span>Joint Account Signup</span></Link></li>
                      <li><Link className="dropdown-item" to={"/corporate-forms/create"}><span>Corporate Account Signup</span></Link></li>
                      <li><Link className="dropdown-item" to={"/estate-forms/create"}><span>Estate Account Signup</span></Link></li>
                    </ul>
                  </li>
                  <li className="dropdown nav-item">
                    <Link className="nav-link" to={"javascript:void(0)"} data-bs-toggle="dropdown">KYC Forms<i className="fas fa-chevron-down fa-xs"></i></Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to={"/individual-kyc-forms/create"}><span>Individual KYC Update Form</span></Link></li>
                      <li><Link className="dropdown-item" to={"/corporate-kyc-forms/create"}><span>Corporate KYC Update Form</span></Link></li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/careers"}><span>Careers</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/contact"}><span>Contact us</span></Link>
                  </li>
                  <li className="nav-item d-sm-none">
                    <a className="nav-link" href="https://www.dynamicportfolioonline.com/IW/IWLogin.aspx" target="_blank"> Account Login </a>
                  </li>
                </ul>
              </div>
              <a className="btn btn-primary btn-md me-5 me-xl-0 d-none d-sm-block btn-account" href="https://www.dynamicportfolioonline.com/IW/IWLogin.aspx" target="_blank"> Account Login </a>
            </div>
          </nav>
          {this.state.prices.length ? <div style={{position: "relative"}}><div style={{position: "absolute", right: 0, fontWeight: "bold", color: "#000", top: 0, zIndex: 10, height: "40px", padding: "10px", background: "rgba(255, 255, 255, 0.9)" }}>{this.state.date}</div>
            <Marquee duration={500000}
              background="rgba(0, 0, 0, 0.8)"
              height="40px"
              width="100%"
              axis="X"
              align="center"
              pauseOnHover={true}
              reverse={true}>{this.priceList()}</Marquee>
              </div>
           : <></>}
        </header>
      </>
    );
  }
}

export default DefaultNavbar;